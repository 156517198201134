import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ProjectWithRelations } from 'silta-ai-backend'
import styled from 'styled-components'
import countryList from 'country-list'
import { toaster } from 'toasterhea'
import cx from 'classnames'
import { Field, Formik, Form as FormikForm } from 'formik'
import { BaseModal, BaseModalProps } from './BaseModal'
import { ProjectDraft } from '../../types/projects'
import {
    EditorModalButtonsContainer,
    EditorModalCloseButton,
    EditorModalContentContainer,
    EditorModalRoot,
    EditorModalSpinner,
    EditorModalSpinnerContainer,
    EditorModalTitle,
    EditorModalTitleContainer,
} from './EditorModalsStyles'
import { RejectionReason } from '../../utils/exceptions'
import { Icon } from '../Icon'
import { TextAreaField } from '../Form/TextAreaField'
import { Button } from '../Button'

import { TextField } from '../Form/TextField'
import { Select } from '../Form/Select/Select'
import { sectors } from '../../utils/sectors'
import {
    emptyProjectDraftDraft,
    useProjectDraft,
    useUpdateProjectDraft,
} from '../../state/ProjectDraft.state'
import { ProjectValidationSchema } from '../../utils/projects'
import { Separator } from '../Separator'
import { themeVariables } from '../../themes/themeVariables'
import { useUsersQuery } from '../../utils/queries'
import { Layer } from '../../utils/layers'
import { useUpdateProject } from '../../utils/mutations'
import { ProjectOfficerImage } from '../ProjectOfficerImage'

const ModalRoot = styled(EditorModalRoot)`
    max-width: 730px;
`

const ProjectIdContainer = styled.div`
    max-width: 128px;
`

const ProjectNameContainer = styled.div`
    flex: 1;
`

const SelectFieldsContainer = styled.div`
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px 25px;
    > * {
        flex: 1;
        min-width: calc(50% - 12.5px);
        max-width: calc(50% - 12.5px);
    }
`

const PrefixIcon = styled(Icon)`
    color: ${themeVariables.colors.secondary};
    margin-right: 8px;
`

const countries = countryList.getNames()
const countryOptions = countries.map((country) => ({
    value: country,
    label: country,
}))

interface EditProjectModalProps extends Omit<BaseModalProps, 'children'> {
    project: ProjectWithRelations
    onConfirm: (projectDraft: ProjectDraft) => Promise<void>
}

const EditProjectModal = ({
    project,
    onConfirm,
    ...props
}: EditProjectModalProps) => {
    const [isLoading, setIsLoading] = useState(false)
    const updateProjectDraft = useUpdateProjectDraft()
    const projectDraft = useProjectDraft()

    const {
        data: users,
        isLoading: isUsersLoading,
        isFetchingNextPage: isFetchingMoreUsers,
        hasNextPage: hasMoreUsers,
        fetchNextPage: fetchMoreUsers,
    } = useUsersQuery({})

    const [countrySearchTerm, setCountrySearchTerm] = useState('')

    const countryValueLabels = useMemo<Record<string, string>>(
        () =>
            Object.fromEntries(countries.map((country) => [country, country])),
        []
    )

    const filteredCountryOptions = useMemo(
        () =>
            countryOptions.filter((country) =>
                country.label
                    .toLowerCase()
                    .includes(countrySearchTerm.toLowerCase())
            ),
        [countrySearchTerm]
    )

    useEffect(() => {
        updateProjectDraft({
            name: project.name,
            description: project.description || undefined,
            country: project.country || undefined,
            sector: project.sector || undefined,
            assignedToId: project.assignedToId || undefined,
            customId: project.customId || undefined,
        })
        return () => {
            updateProjectDraft(emptyProjectDraftDraft)
        }
    }, [])

    return (
        <BaseModal {...props}>
            <ModalRoot>
                <EditorModalTitleContainer>
                    <EditorModalTitle>Edit project</EditorModalTitle>
                    <EditorModalCloseButton
                        onClick={() =>
                            props.onReject?.(RejectionReason.CloseButton)
                        }
                    >
                        <Icon name="close2" />
                    </EditorModalCloseButton>
                </EditorModalTitleContainer>
                <EditorModalContentContainer className="m-t-20">
                    <Formik
                        onSubmit={() =>
                            onConfirm(projectDraft).then(() => {
                                setIsLoading(false)
                                props.onResolve?.()
                            })
                        }
                        initialValues={{
                            name: project.name,
                            description: project.description || undefined,
                            country: project.country || undefined,
                            sector: project.sector || undefined,
                            assignedToId: project.assignedToId || undefined,
                            customId: project.customId || undefined,
                        }}
                        validationSchema={ProjectValidationSchema}
                    >
                        <FormikForm className="w-100">
                            <div className={cx('d-flex', 'g-25', 'm-b-30')}>
                                <ProjectIdContainer>
                                    <Field name="customId">
                                        {({
                                            field,
                                            form,
                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        }: any) => (
                                            <TextField
                                                {...field}
                                                value={
                                                    projectDraft.customId || ''
                                                }
                                                errorMessage={
                                                    form.touched[field.name] &&
                                                    form.errors[field.name]
                                                }
                                                label="Project ID"
                                                placeholder="e.g. 51234-012"
                                                onChange={(e) => {
                                                    field.onChange(e)
                                                    updateProjectDraft({
                                                        customId:
                                                            e.target.value,
                                                    })
                                                }}
                                            />
                                        )}
                                    </Field>
                                </ProjectIdContainer>

                                <ProjectNameContainer>
                                    <Field name="name">
                                        {({
                                            field,
                                            form,
                                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        }: any) => (
                                            <TextField
                                                {...field}
                                                value={projectDraft.name || ''}
                                                errorMessage={
                                                    form.touched[field.name] &&
                                                    form.errors[field.name]
                                                }
                                                label="Project name"
                                                placeholder="Please specify the project name"
                                                onChange={(e) => {
                                                    field.onChange(e)
                                                    updateProjectDraft({
                                                        name: e.target.value,
                                                    })
                                                }}
                                            />
                                        )}
                                    </Field>
                                </ProjectNameContainer>
                            </div>

                            <Field name="description">
                                {({
                                    field,
                                    form,
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                }: any) => (
                                    <TextAreaField
                                        {...field}
                                        value={projectDraft.description || ''}
                                        label="Project description"
                                        placeholder="Please provide a summary description of the project"
                                        errorMessage={
                                            form.touched[field.name] &&
                                            form.errors[field.name]
                                        }
                                        onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                            field.onChange(event)
                                            updateProjectDraft({
                                                description: event.target.value,
                                            })
                                        }}
                                    />
                                )}
                            </Field>

                            <SelectFieldsContainer>
                                <Field name="country">
                                    {({
                                        field,
                                        form,
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    }: any) => (
                                        <Select
                                            {...field}
                                            value={projectDraft.country}
                                            valueLabels={countryValueLabels}
                                            errorMessage={
                                                form.touched[field.name] &&
                                                form.errors[field.name]
                                            }
                                            additionalLabel="Optional"
                                            label="Country"
                                            options={filteredCountryOptions}
                                            onSearchChange={
                                                setCountrySearchTerm
                                            }
                                            placeholder="Select a Country"
                                            onChange={(selection: string) => {
                                                form.setFieldValue(
                                                    field.name,
                                                    selection
                                                )
                                                field.onChange(selection || '')
                                                updateProjectDraft({
                                                    country: selection,
                                                })
                                            }}
                                            prefixContent={
                                                <PrefixIcon name="globe" />
                                            }
                                        />
                                    )}
                                </Field>

                                <Field name="assignedToId">
                                    {({
                                        field,
                                        form,
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    }: any) => (
                                        <Select
                                            {...field}
                                            valueLabels={
                                                project.assignedTo
                                                    ? {
                                                          [project.assignedTo
                                                              .id]:
                                                              project.assignedTo
                                                                  .name,
                                                      }
                                                    : {}
                                            }
                                            value={projectDraft.assignedToId}
                                            // containerClassName="m-b-30"
                                            errorMessage={
                                                form.touched[field.name] &&
                                                form.errors[field.name]
                                            }
                                            label="Project Officer"
                                            loadingOptions={isUsersLoading}
                                            loadingMoreOptions={
                                                isFetchingMoreUsers
                                            }
                                            hasMoreOptions={hasMoreUsers}
                                            onFetchMoreClick={fetchMoreUsers}
                                            options={(users || []).map(
                                                (user) => ({
                                                    value: user.id,
                                                    label: user.name,
                                                })
                                            )}
                                            placeholder="Select a Project Officer"
                                            onChange={(selection: string) => {
                                                form.setFieldValue(
                                                    field.name,
                                                    selection
                                                )
                                                field.onChange(selection || '')
                                                updateProjectDraft({
                                                    assignedToId: selection,
                                                })
                                            }}
                                            prefixContent={
                                                <ProjectOfficerImage
                                                    assignedToId={
                                                        projectDraft.assignedToId
                                                    }
                                                    users={users || []}
                                                />
                                            }
                                        />
                                    )}
                                </Field>

                                <Field name="sector">
                                    {({
                                        field,
                                        form,
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    }: any) => (
                                        <Select
                                            {...field}
                                            value={projectDraft.sector}
                                            // containerClassName="m-b-30"
                                            errorMessage={
                                                form.touched[field.name] &&
                                                form.errors[field.name]
                                            }
                                            label="Sector"
                                            additionalLabel="Optional"
                                            options={sectors.map((sector) => ({
                                                value: sector,
                                                label: sector,
                                            }))}
                                            placeholder="Select a Sector"
                                            onChange={(selection: string) => {
                                                form.setFieldValue(
                                                    field.name,
                                                    selection
                                                )
                                                field.onChange(selection || '')
                                                updateProjectDraft({
                                                    sector: selection,
                                                })
                                            }}
                                            prefixContent={
                                                <PrefixIcon name="parthenon" />
                                            }
                                        />
                                    )}
                                </Field>
                            </SelectFieldsContainer>
                            <Separator className={cx('m-t-30', 'm-b-20')} />
                        </FormikForm>
                    </Formik>
                </EditorModalContentContainer>
                <EditorModalButtonsContainer>
                    <Button
                        $variant="secondary"
                        onClick={() =>
                            props.onReject?.(RejectionReason.CancelButton)
                        }
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            setIsLoading(true)
                            onConfirm(projectDraft).then(() => {
                                setIsLoading(false)
                                props.onResolve?.()
                            })
                        }}
                        disabled={
                            !ProjectValidationSchema.isValidSync(
                                projectDraft
                            ) ||
                            isLoading ||
                            isUsersLoading
                        }
                    >
                        {isLoading ? (
                            <EditorModalSpinnerContainer>
                                Saving
                                <EditorModalSpinner name="spinner" />
                            </EditorModalSpinnerContainer>
                        ) : (
                            'Save'
                        )}
                    </Button>
                </EditorModalButtonsContainer>
            </ModalRoot>
        </BaseModal>
    )
}

export const useEditProjectModal = (projectId: string) => {
    const updateProject = useUpdateProject(projectId)

    const editModal = toaster(EditProjectModal, Layer.Modal)

    return useCallback(
        async (project: ProjectWithRelations) => {
            try {
                await editModal.pop({
                    project,
                    onConfirm: async (newProject) => {
                        await updateProject.mutateAsync({
                            project: newProject,
                        })
                    },
                })
            } catch (e) {
                // do nothing
            }
        },
        [editModal, updateProject]
    )
}
