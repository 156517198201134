import React, { useCallback, useMemo } from 'react'
import cx from 'classnames'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { DataRoomDocument } from 'silta-ai-backend'
import { downloadDocument } from 'utils/download'
import {
    invalidateDataRoomDocumentsQuery,
    useDataRoomDocumentsQuery,
    useProjectQuery,
} from '../utils/queries'
import { Button } from './Button'
import { route } from '../utils/routes'
import { Icon } from './Icon'
import {
    Table,
    TableNoContentContainer,
    TableNoContentIcon,
    TableNoContentIconContainer,
} from './Table'
import { FileTypeIcon } from './FileTypeIcon'
import { DateRenderer } from './renderers/DateRenderer'
import { Dropdown, DropdownContent } from './Dropdown'
import { DotdotdotButton } from './DotdotdotButton'
import {
    SelectBasicItem,
    SelectBasicItemIconWrap,
} from './Form/Select/SelectItemRenderer'
import { confirmModal } from './modals/ConfirmationModal'
import { useDeleteDocument } from '../utils/mutations'
import { Separator } from './Separator'
import { LoadMoreButton } from './LoadMoreButton'
import {
    useProjectFocusedDocument,
    useUpdateProjectFocusedDocument,
} from '../state/ProjectFocusedDocument.state'

const DropdownIcon = styled(Icon)`
    width: 16px;
    height: 16px;
    svg {
        width: 16px;
        height: 16px;
    }
`

export const ProjectDataRoomDocuments = ({
    projectId,
    tableTitle,
    className,
    limit,
    showUploadButton = false,
    hideLoadMoreButton = false,
}: {
    projectId: string
    tableTitle: string
    className?: string
    limit?: number
    showUploadButton?: boolean
    hideLoadMoreButton?: boolean
}) => {
    const { data: project, isFetching } = useProjectQuery({ projectId })
    const focusedDocument = useProjectFocusedDocument()
    const updateFocusedDocument = useUpdateProjectFocusedDocument()
    const {
        data: documents,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
    } = useDataRoomDocumentsQuery({
        dataRoomId: project?.dataRoomId,
        pageSize: limit,
    })

    const navigate = useNavigate()

    const deleteDocument = useDeleteDocument()

    const triggerDeleteDocument = useCallback(
        async (doc: DataRoomDocument) => {
            try {
                const confirmed = await confirmModal.pop({
                    title: 'Delete Document?',
                    content: `Are you sure you want to delete document “${doc.originalFileName}”?`,
                    confirmButtonText: 'Delete Document',
                    cancelButtonText: 'Cancel',
                })
                if (confirmed) {
                    deleteDocument.mutate(
                        { documentId: doc.id, projectId },
                        {
                            onSuccess: () => {
                                invalidateDataRoomDocumentsQuery(
                                    project?.dataRoomId,
                                    { pageSize: limit }
                                )
                                if (focusedDocument?.id === doc.id) {
                                    updateFocusedDocument(null)
                                }
                            },
                        }
                    )
                }
            } catch (e) {
                // do nothing
            }
        },
        [projectId, project, focusedDocument]
    )

    const hoveredIndex = useMemo(() => {
        return documents.findIndex((doc) => doc.id === focusedDocument?.id)
    }, [documents, focusedDocument])

    return (
        <div className={className}>
            <div
                className={cx(
                    'd-flex',
                    'align-items-center',
                    'justify-content-between',
                    'm-b-30'
                )}
            >
                <h3>{tableTitle}</h3>
                {documents && documents.length > 0 && showUploadButton && (
                    <div className={cx('d-flex', 'g-5')}>
                        <Button
                            $variant="secondary"
                            $size="small"
                            onClick={() =>
                                navigate(route('projectDataRoom', projectId))
                            }
                            className={cx('d-flex', 'g-5')}
                        >
                            <Icon name="upload" />
                            Upload Documents
                        </Button>
                        <Button
                            $variant="secondary"
                            $size="small"
                            onClick={() =>
                                navigate(route('projectDataRoom', projectId))
                            }
                        >
                            View all {project?.dataRoom._count.documents}{' '}
                            documents
                        </Button>
                    </div>
                )}
            </div>
            {documents && documents.length > 0 && (
                <>
                    <Table
                        columns={[
                            {
                                key: 'originalFileName',
                                title: 'Name',
                                width: 'minmax(min-content, 90%)',
                                render: ({ originalFileName }) => (
                                    <div className={cx('d-flex', 'g-10')}>
                                        <FileTypeIcon
                                            fileName={originalFileName}
                                        />
                                        {originalFileName}
                                    </div>
                                ),
                            },
                            { key: 'wordCount', title: 'Words' },
                            {
                                key: 'createdAt',
                                title: 'Added on',
                                render: ({ createdAt }) => (
                                    <DateRenderer value={createdAt} />
                                ),
                            },
                            {
                                key: 'meatballMenu',
                                title: '',
                                render: (doc) => (
                                    <>
                                        <Dropdown
                                            trigger={({ onClick }) => (
                                                <DotdotdotButton
                                                    onClick={(event) => {
                                                        event.stopPropagation()
                                                        onClick()
                                                    }}
                                                    flip
                                                />
                                            )}
                                        >
                                            {(dismiss) => (
                                                <DropdownContent>
                                                    <SelectBasicItem
                                                        type="button"
                                                        onClick={(event) => {
                                                            event.stopPropagation()
                                                            dismiss()
                                                            downloadDocument(
                                                                doc
                                                            )
                                                        }}
                                                    >
                                                        <SelectBasicItemIconWrap>
                                                            <DropdownIcon name="download" />
                                                        </SelectBasicItemIconWrap>
                                                        Download
                                                    </SelectBasicItem>
                                                    <SelectBasicItem
                                                        type="button"
                                                        onClick={(event) => {
                                                            event.stopPropagation()
                                                            dismiss()
                                                            triggerDeleteDocument(
                                                                doc
                                                            )
                                                        }}
                                                    >
                                                        <SelectBasicItemIconWrap>
                                                            <DropdownIcon name="trash" />
                                                        </SelectBasicItemIconWrap>
                                                        Delete
                                                    </SelectBasicItem>
                                                </DropdownContent>
                                            )}
                                        </Dropdown>
                                    </>
                                ),
                            },
                        ]}
                        data={documents}
                        noHorizontalPadding
                        onRowClick={(doc) => {
                            if (focusedDocument?.id === doc.id) {
                                updateFocusedDocument(null)
                            } else {
                                updateFocusedDocument(doc)
                            }
                        }}
                        hoveredIndex={hoveredIndex}
                    />
                    {!hideLoadMoreButton && hasNextPage && (
                        <LoadMoreButton
                            onClick={fetchNextPage}
                            isLoading={isFetchingNextPage}
                        />
                    )}
                </>
            )}
            {documents && documents.length === 0 && !isFetching && (
                <>
                    <TableNoContentContainer>
                        <TableNoContentIconContainer>
                            <TableNoContentIcon name="upload" />
                        </TableNoContentIconContainer>
                        <div className={cx('large', 'emphasized')}>
                            Upload first Documents to Project Data Room
                        </div>
                        <div className="secondary">
                            Project Data Rooms will be great assets for
                            Evaluation Criteria{' '}
                        </div>
                        {showUploadButton && (
                            <Button
                                onClick={() =>
                                    navigate(
                                        route('projectDataRoom', projectId)
                                    )
                                }
                                className={cx('d-flex', 'g-5', 'm-t-25')}
                            >
                                <Icon name="upload" />
                                Upload Documents
                            </Button>
                        )}
                    </TableNoContentContainer>
                    <Separator className="m-t-90" />
                </>
            )}
        </div>
    )
}
