import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import cx from 'classnames'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup'
import { Navigate } from 'react-router'
import { useRequiredParam } from '../utils/misc'
import { useSignupCodeQuery } from '../utils/queries'
import { themeVariables } from '../themes/themeVariables'
import { Icon } from '../components/Icon'
import { Logo } from '../components/Logo'
import { Button } from '../components/Button'
import { TextField } from '../components/Form/TextField'
import { BaseButton } from '../components/BaseButton'
import { validatePassword } from '../utils/password'
import { useCropImageModal } from '../components/modals/CropImageModal'
import { useCreateUser } from '../utils/mutations'
import { useAuth } from '../providers/AuthProvider'
import { route } from '../utils/routes'
import { InfoBox } from '../components/InfoBox'
import { TextLink } from '../components/TextLink'

const appName = process.env.REACT_APP_NAME

if (!appName) {
    throw new Error('REACT_APP_NAME is not set')
}

const FullPageLayoutContainer = styled.div`
    width: 100%;
    height: 100vh;
    background-color: ${themeVariables.colors.backgroundContainer};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
`

const TopContainer = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 26px;
`

const ContentContainer = styled.div`
    max-width: 538px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${themeVariables.colors.backgroundSurface};
    margin: 37px 0;
    padding: 60px;
    box-shadow:
        0px 15px 35px 0px #3c425714,
        0px 5px 15px 0px #0000001f;
`

const Spinner = styled(Icon)`
    margin-top: 50vh;
    width: 100px;
    height: 100px;
    svg {
        width: 100px;
        height: 100px;
    }
`

const AvatarIcon = styled(Icon)`
    color: ${themeVariables.colors.secondary};
    width: 72px;
    height: 72px;
    svg {
        width: 72px;
        height: 72px;
    }
`

const HoverContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    display: none; // it's changed by hover
    justify-content: center;
    align-items: center;
    color: ${themeVariables.colors.backgroundSurface};
`

const CamerIconContainer = styled.div`
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: rgba(48, 49, 60, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
`

const AvatarContainer = styled.div`
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: ${themeVariables.colors.backgroundContainer};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;

    &:hover ${HoverContainer} {
        display: flex;
    }
`

const SignUpValidationSchema = yup.object({
    name: yup.string().required('Name is required'),
    password: yup
        .string()
        .required('Password is required')
        .test('is-valid-password', 'Invalid password', (value) =>
            validatePassword(value || '')
        ),
    passwordRepeat: yup
        .string()
        .required('Retyping password is required')
        .oneOf([yup.ref('password'), null], 'Passwords must match'),
})

enum SignUpPageStep {
    SignUp = 'signUp',
    Avatar = 'avatar',
}

export const SignUpPage = () => {
    const cropImageModal = useCropImageModal()
    const createNewUser = useCreateUser()
    const { session } = useAuth()
    const signupCode = useRequiredParam('signupCode')
    const { data, isFetching, error } = useSignupCodeQuery(signupCode)
    const [step, setStep] = useState(SignUpPageStep.SignUp)
    const [name, setName] = useState('')
    const [password, setPassword] = useState('')
    const [passwordRepeat, setPasswordRepeat] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordRepeat, setShowPasswordRepeat] = useState(false)
    const showSpinner = useMemo(
        () => isFetching || createNewUser.isPending,
        [isFetching, createNewUser.isPending]
    )

    const handleSubmit = useCallback(
        async (file?: File) => {
            if (!data) {
                return
            }
            createNewUser.mutate({
                name,
                email: data.email,
                password,
                signupCode,
                avatar: file,
            })
        },
        [signupCode, data, name, password, passwordRepeat]
    )

    const triggerAvatarSelection = useCallback(() => {
        const fileInput = document.createElement('input')
        fileInput.type = 'file'
        fileInput.accept = '.jpg, .jpeg, .png'
        fileInput.click()
        fileInput.onchange = async (e) => {
            const file = (e.target as HTMLInputElement)?.files?.[0]
            if (file) {
                const fileUrl = URL.createObjectURL(file)
                await cropImageModal({
                    imageUrl: fileUrl,
                    onCrop: async (file) => {
                        handleSubmit(file)
                    },
                })
            }
        }
    }, [handleSubmit, cropImageModal])

    if (session) {
        // Redirect user to the home page if already logged in
        return <Navigate to={route('home')} />
    }

    return (
        <FullPageLayoutContainer>
            {showSpinner ? (
                <Spinner name="spinnerBlueThin" />
            ) : (
                <>
                    <TopContainer>
                        <span className="secondary">
                            {data && data.email ? (
                                <>
                                    Sign up as:{' '}
                                    <strong
                                        className={cx('emphasized', 'primary')}
                                    >
                                        {data.email}
                                    </strong>
                                </>
                            ) : (
                                '\u200D'
                            )}
                        </span>
                    </TopContainer>
                    <Logo />
                    <h1 className="m-t-75">
                        {step === SignUpPageStep.SignUp
                            ? `Create a ${appName} Account`
                            : 'Pick a profile picture'}
                    </h1>
                    <ContentContainer>
                        {step === SignUpPageStep.SignUp ? (
                            <>
                                {error ? (
                                    <InfoBox
                                        iconName="warningTriangleLight"
                                        borderColor={
                                            themeVariables.colors.critical
                                        }
                                        backgroundColor={
                                            themeVariables.palettes.critical100
                                        }
                                        textColor={
                                            themeVariables.colors.primary
                                        }
                                        iconColor={
                                            themeVariables.colors.critical
                                        }
                                    >
                                        <span
                                            className={cx(
                                                'primary',
                                                'emphasized',
                                                'large'
                                            )}
                                        >
                                            The invitation link does not appear
                                            to be valid
                                        </span>
                                    </InfoBox>
                                ) : (
                                    <div>
                                        <Formik
                                            onSubmit={() => {
                                                // todo
                                            }}
                                            initialValues={{}}
                                            validationSchema={
                                                SignUpValidationSchema
                                            }
                                        >
                                            <Form>
                                                <div className="m-b-30">
                                                    <Field name="name">
                                                        {({
                                                            field,
                                                            form,
                                                        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                        any) => (
                                                            <TextField
                                                                {...field}
                                                                label="Name"
                                                                value={
                                                                    name || ''
                                                                }
                                                                errorMessage={
                                                                    form
                                                                        .touched[
                                                                        field
                                                                            .name
                                                                    ] &&
                                                                    form.errors[
                                                                        field
                                                                            .name
                                                                    ]
                                                                }
                                                                placeholder="Enter your name"
                                                                type="text"
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    field.onChange(
                                                                        e
                                                                    )
                                                                    setName(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }}
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                                <div className="m-b-30">
                                                    <Field name="password">
                                                        {({
                                                            field,
                                                            form,
                                                        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                        any) => (
                                                            <TextField
                                                                {...field}
                                                                label="Password"
                                                                value={
                                                                    password ||
                                                                    ''
                                                                }
                                                                errorMessage={
                                                                    form
                                                                        .touched[
                                                                        field
                                                                            .name
                                                                    ] &&
                                                                    form.errors[
                                                                        field
                                                                            .name
                                                                    ]
                                                                }
                                                                placeholder="Enter your password"
                                                                type={
                                                                    showPassword
                                                                        ? 'text'
                                                                        : 'password'
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    field.onChange(
                                                                        e
                                                                    )
                                                                    setPassword(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }}
                                                                additionalInnerContent={
                                                                    <BaseButton
                                                                        type="button"
                                                                        onClick={() =>
                                                                            setShowPassword(
                                                                                !showPassword
                                                                            )
                                                                        }
                                                                    >
                                                                        <Icon
                                                                            className="secondary"
                                                                            name={
                                                                                showPassword
                                                                                    ? 'eyeOff'
                                                                                    : 'eye'
                                                                            }
                                                                        />
                                                                    </BaseButton>
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                    <p
                                                        className={cx(
                                                            'secondary',
                                                            'm-t-10'
                                                        )}
                                                    >
                                                        Use at least 16
                                                        characters long.
                                                        Contains upper and lower
                                                        case characters.
                                                        Contains at least one
                                                        number. Contains at
                                                        least one special
                                                        character.
                                                    </p>
                                                </div>
                                                <div className="m-b-30">
                                                    <Field name="passwordRepeat">
                                                        {({
                                                            field,
                                                            form,
                                                        }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                                        any) => (
                                                            <TextField
                                                                {...field}
                                                                label="Confirm Password"
                                                                value={
                                                                    passwordRepeat ||
                                                                    ''
                                                                }
                                                                errorMessage={
                                                                    form
                                                                        .touched[
                                                                        field
                                                                            .name
                                                                    ] &&
                                                                    form.errors[
                                                                        field
                                                                            .name
                                                                    ]
                                                                }
                                                                placeholder="Confirm your password"
                                                                type={
                                                                    showPasswordRepeat
                                                                        ? 'text'
                                                                        : 'password'
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    field.onChange(
                                                                        e
                                                                    )
                                                                    setPasswordRepeat(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }}
                                                                additionalInnerContent={
                                                                    <BaseButton
                                                                        type="button"
                                                                        onClick={() =>
                                                                            setShowPasswordRepeat(
                                                                                !showPasswordRepeat
                                                                            )
                                                                        }
                                                                    >
                                                                        <Icon
                                                                            className="secondary"
                                                                            name={
                                                                                showPasswordRepeat
                                                                                    ? 'eyeOff'
                                                                                    : 'eye'
                                                                            }
                                                                        />
                                                                    </BaseButton>
                                                                }
                                                            />
                                                        )}
                                                    </Field>
                                                </div>
                                            </Form>
                                        </Formik>
                                        <Button
                                            $size="large"
                                            className="w-100"
                                            onClick={() =>
                                                setStep(SignUpPageStep.Avatar)
                                            }
                                            type="button"
                                            disabled={
                                                !SignUpValidationSchema.isValidSync(
                                                    {
                                                        name,
                                                        password,
                                                        passwordRepeat,
                                                    }
                                                )
                                            }
                                        >
                                            Create account
                                        </Button>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div
                                className={cx(
                                    'd-flex',
                                    'flex-column',
                                    'align-items-center',
                                    'w-100'
                                )}
                            >
                                <AvatarContainer
                                    onClick={triggerAvatarSelection}
                                >
                                    <AvatarIcon name="userCircle" />
                                    <HoverContainer>
                                        <CamerIconContainer>
                                            <Icon name="camera" />
                                        </CamerIconContainer>
                                    </HoverContainer>
                                </AvatarContainer>
                                <Button
                                    $variant="secondary"
                                    $size="large"
                                    className={cx('w-100', 'm-t-60')}
                                    onClick={() => handleSubmit()}
                                >
                                    Skip for now
                                </Button>
                            </div>
                        )}
                    </ContentContainer>

                    {error && (
                        <p className={cx('secondary', 'm-t-20')}>
                            Already have an account?{' '}
                            <TextLink to={route('login')}>Login</TextLink>
                        </p>
                    )}
                </>
            )}
        </FullPageLayoutContainer>
    )
}
