import cx from 'classnames'
import { DateRenderer } from 'components/renderers/DateRenderer'
import { Table } from 'components/Table'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'
import { useModelsQuery } from 'utils/queries'
import { Button } from '../components/Button'
import { HorizontalLoadingIndicator } from '../components/HorizontalLoadingIndicator'
import { Icon } from '../components/Icon'
import { Layout } from '../components/Layout'
import { ListTopBar } from '../components/ListTopBar'
import { TableDataLoading } from '../components/TableDataLoading'
import { TableNoContent } from '../components/TableNoContent'
import { route } from '../utils/routes'
import { useCreateModel } from '../utils/mutations'
import { useNameInputModal } from '../components/modals/NameInputModal'
import { LoadMoreButton } from '../components/LoadMoreButton'

export function ModelListPage() {
    const {
        data: models,
        isLoading,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
    } = useModelsQuery({})

    const navigate = useNavigate()

    const createModel = useCreateModel()
    const openNameInputModal = useNameInputModal()

    const handleCreateModel = useCallback(() => {
        openNameInputModal({
            onConfirm: async (name) => {
                await createModel.mutateAsync(name)
            },
            modalTitle: 'Create Evaluation Criteria',
            buttonCtaText: 'Save',
            buttonCtaSavingText: 'Saving',
        })
    }, [createModel, openNameInputModal])

    return (
        <Layout>
            <ListTopBar />
            <HorizontalLoadingIndicator
                loading={isLoading || isFetchingNextPage}
            />
            <div
                className={cx(
                    'p-l-40',
                    'p-r-40',
                    'p-t-30',
                    'p-b-15',
                    'd-flex',
                    'align-items-center',
                    'justify-space-between'
                )}
            >
                <h1>Evaluation Criteria</h1>
                <Button $size="medium" onClick={handleCreateModel}>
                    <div className="d-flex align-items-center">
                        <Icon name="plus" />
                        <span className="m-l-5">
                            Create Evaluation Criteria
                        </span>
                    </div>
                </Button>
            </div>
            <Table
                columns={[
                    {
                        title: 'Name',
                        key: 'name',
                        render: (model) => (
                            <span className="emphasized">{model.name}</span>
                        ),
                        width: 'minmax(min-content, 80%)',
                    },
                    {
                        title: 'Questions',
                        key: 'questionCount',
                        render: (model) => (
                            <span className="emphasized">
                                {model._count.questions}
                            </span>
                        ),
                    },
                    {
                        title: 'Last updated',
                        key: 'updatedAt',
                        render: (model) => (
                            <DateRenderer value={model.updatedAt} />
                        ),
                    },
                ]}
                data={models}
                onRowClick={(model) => {
                    navigate(route('model', model.id))
                }}
                stickyHeader
            />
            {isLoading && <TableDataLoading />}
            {!isLoading && models.length === 0 && (
                <TableNoContent
                    icon="server"
                    title="Create first Evaluation Criteria"
                    description="Evaluation Criteria are sets of questions to evaluate Projects."
                    buttonText="Create Evaluation Criteria"
                    buttonOnClick={handleCreateModel}
                />
            )}
            {hasNextPage && (
                <LoadMoreButton
                    onClick={fetchNextPage}
                    isLoading={isFetchingNextPage}
                />
            )}
        </Layout>
    )
}
