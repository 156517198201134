import React, { useCallback, useMemo, useState } from 'react'
import styled, { css } from 'styled-components'
import cx from 'classnames'
import { AnswerActivityWithRelations } from 'silta-ai-backend'
import { useAnswerActivitiesQuery } from '../utils/queries'
import { UserAvatar } from './UserAvatar'
import { UserAvatarPlaceholder } from './UserAvatarPlaceholder'
import { Tooltip } from './Tooltip'
import { useAuth } from '../providers/AuthProvider'
import { DotdotdotButton } from './DotdotdotButton'
import { Button } from './Button'
import { TextAreaField } from './Form/TextAreaField'
import { Icon } from './Icon'
import {
    useCreateAnswerComment,
    useDeleteAnswerComment,
    useUpdateAnswerComment,
} from '../utils/mutations'
import { themeVariables } from '../themes/themeVariables'
import { Dropdown, DropdownContent } from './Dropdown'
import { SelectBasicItem } from './Form/Select/SelectItemRenderer'
import { confirmModal } from './modals/ConfirmationModal'
import { LoadMoreButton } from './LoadMoreButton'

// yeah it's a duplication from Prisma schema but we cannot export enum from the backend package, only types
enum AnswerActivityType {
    OutcomeChange = 'OutcomeChange',
    ReviewChange = 'ReviewChange',
    Run = 'Run',
    QuestionChange = 'QuestionChange',
    Comment = 'Comment',
}

const StatusMap = new Map([
    ['ReadyForReview', 'Ready For Review'],
    ['Accepted', 'Accepted'],
    ['Rejected', 'Rejected'],
])

const ActivityList = styled.ul`
    list-style: none;
    padding: 0;
`

const ActivityItem = styled.li<{ $isComment: boolean }>`
    padding: 16px 0;
    ${({ $isComment }) =>
        $isComment &&
        css`
            background-color: ${themeVariables.colors.backgroundContainer};
        `}
`

const SimpleActivityContent = styled.div`
    display: flex;
    align-items: flex-start;
`

const AvatarContainer = styled.div`
    margin: 0 16px;
`

const StyledTextAreaField = styled(TextAreaField)`
    padding: 16px 16px 40px;
    background-color: ${themeVariables.colors.backgroundSurface};
    min-height: 120px;
`

const CommentButtonContainer = styled.div`
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: ${themeVariables.colors.backgroundSurface};
`

const AddCommentButton = styled(Button)`
    border-radius: 50%;
    width: 32px;
    height: 32px;
    padding: 0;
    &:disabled {
        background-color: ${themeVariables.palettes.neutral400};
        border-color: ${themeVariables.palettes.neutral400};
    }
`

const CommentDisplay = styled.p`
    white-space: pre-wrap;
    padding: 0 16px;
    margin-top: 16px;
    margin-bottom: 0;
`

const formatDate = (date: Date): string => {
    return date.toLocaleDateString('en-UK', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    })
}

const formatRelativeDate = (date: Date) => {
    const now = new Date()

    if (
        now.getFullYear() === date.getFullYear() &&
        now.getMonth() === date.getMonth() &&
        now.getDate() === date.getDate()
    ) {
        return 'Today'
    }

    const yesterday = new Date(now)
    yesterday.setDate(now.getDate() - 1)

    if (
        yesterday.getFullYear() === date.getFullYear() &&
        yesterday.getMonth() === date.getMonth() &&
        yesterday.getDate() === date.getDate()
    ) {
        return 'Yesterday'
    }

    const diffInDays = Math.floor(
        (now.getTime() - date.getTime()) / 1000 / 60 / 60 / 24
    )
    return `${diffInDays} days ago`
}

const checkIfActivityWasEdited = (activity: AnswerActivityWithRelations) => {
    return (
        activity.updatedAt &&
        activity.updatedAt.getTime() !== activity.createdAt.getTime()
    )
}

const checkIfCommentIsEditable = (
    activity: AnswerActivityWithRelations,
    currentUserId?: string
) => {
    return (
        activity.type === AnswerActivityType.Comment &&
        activity.userId === currentUserId
    )
}

const ActivityContent = ({
    activity,
}: {
    activity: AnswerActivityWithRelations
}) => {
    const action = useMemo(() => {
        switch (activity.type) {
            case AnswerActivityType.OutcomeChange:
                return (
                    <>
                        changed the outcome from{' '}
                        <span className="emphasized">
                            {activity.outcomeChangeFrom}
                        </span>{' '}
                        to{' '}
                        <span className="emphasized">
                            {activity.outcomeChangeTo}
                        </span>
                    </>
                )
            case AnswerActivityType.ReviewChange:
                return (
                    <>
                        changed the status from{' '}
                        <span className="emphasized">
                            {StatusMap.get(activity.reviewStatusChangeFrom!) ??
                                activity.reviewStatusChangeFrom}
                        </span>{' '}
                        to{' '}
                        <span className="emphasized">
                            {StatusMap.get(activity.reviewStatusChangeTo!) ??
                                activity.reviewStatusChangeTo}
                        </span>
                    </>
                )
            case AnswerActivityType.Run:
                return 'ran the question'
            case AnswerActivityType.QuestionChange:
                return 'changed the question'
            case AnswerActivityType.Comment:
            default:
                return ''
        }
    }, [activity])
    return (
        <>
            <span className="emphasized">{activity.user.name}</span>{' '}
            <span>{action}</span>
        </>
    )
}

export const AnswerActivities = ({ answerId }: { answerId: string }) => {
    const { session } = useAuth()
    const currentUserId = session?.userId
    const { data, hasNextPage, fetchNextPage, isFetchingNextPage } =
        useAnswerActivitiesQuery(answerId)

    const activities = useMemo(
        () => data?.pages.flatMap((page) => page.activities) ?? [],
        [data]
    )

    const [editedCommentId, setEditedCommentId] = useState<string | null>(null)
    const [editedCommentContent, setEditedCommentContent] = useState('')
    const [comment, setComment] = useState('')

    const createComment = useCreateAnswerComment()
    const handleCreateComment = useCallback(() => {
        if (comment) {
            createComment.mutate(
                { answerId, comment },
                {
                    onSuccess: () => {
                        setComment('')
                    },
                }
            )
        }
    }, [comment, answerId, createComment])

    const deleteComment = useDeleteAnswerComment()
    const updateComment = useUpdateAnswerComment()

    const handleUpdateComment = useCallback(() => {
        if (editedCommentContent && editedCommentId) {
            updateComment.mutate(
                { commentId: editedCommentId, comment: editedCommentContent },
                {
                    onSuccess: () => {
                        setEditedCommentId(null)
                        setEditedCommentContent('')
                    },
                }
            )
        }
    }, [editedCommentContent, answerId, updateComment])

    return (
        <div className={cx('m-t-50', 'p-b-30')}>
            <p className={cx('large', 'emphasized', 'm-b-30')}>Activity</p>
            <ActivityList>
                {activities.map((a) => (
                    <ActivityItem
                        key={a.id}
                        $isComment={a.type === AnswerActivityType.Comment}
                    >
                        <SimpleActivityContent>
                            <AvatarContainer>
                                {a.user.avatarBlobId ? (
                                    <UserAvatar
                                        avatarBlobId={a.user.avatarBlobId}
                                        size={40}
                                    />
                                ) : (
                                    <UserAvatarPlaceholder
                                        userName={a.user.name}
                                        size={40}
                                    />
                                )}
                            </AvatarContainer>
                            <div className={cx('d-flex', 'flex-1')}>
                                {editedCommentId === a.id ? (
                                    <div className={cx('flex-1', 'p-r-15')}>
                                        <StyledTextAreaField
                                            value={editedCommentContent ?? ''}
                                            placeholder="Leave a comment..."
                                            onChange={(e) =>
                                                setEditedCommentContent(
                                                    e.target.value
                                                )
                                            }
                                            disabled={updateComment.isPending}
                                            containerClassName="relative"
                                            bottomContent={
                                                <CommentButtonContainer>
                                                    <Button
                                                        disabled={
                                                            updateComment.isPending
                                                        }
                                                        $variant="secondary"
                                                        onClick={() => {
                                                            setEditedCommentId(
                                                                null
                                                            )
                                                            setEditedCommentContent(
                                                                ''
                                                            )
                                                        }}
                                                    >
                                                        Cancel
                                                    </Button>
                                                    <Button
                                                        onClick={
                                                            handleUpdateComment
                                                        }
                                                        disabled={
                                                            !editedCommentContent ||
                                                            editedCommentContent ===
                                                                a.comment ||
                                                            updateComment.isPending
                                                        }
                                                    >
                                                        {updateComment.isPending
                                                            ? 'Saving...'
                                                            : 'Save'}
                                                    </Button>
                                                </CommentButtonContainer>
                                            }
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className={cx(
                                            'd-flex',
                                            'justify-content-between',
                                            'w-100'
                                        )}
                                    >
                                        <div>
                                            <p className="m-b-0">
                                                <ActivityContent activity={a} />
                                            </p>
                                            <div className="d-flex">
                                                <Tooltip
                                                    content={formatDate(
                                                        a.createdAt
                                                    )}
                                                >
                                                    <small className="secondary">
                                                        {formatRelativeDate(
                                                            a.createdAt
                                                        )}{' '}
                                                    </small>
                                                </Tooltip>
                                                {checkIfActivityWasEdited(
                                                    a
                                                ) && (
                                                    <Tooltip
                                                        content={formatDate(
                                                            a.updatedAt
                                                        )}
                                                    >
                                                        <small
                                                            className={cx(
                                                                'secondary',
                                                                'emphasized'
                                                            )}
                                                        >
                                                            • Edited
                                                        </small>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </div>
                                        {checkIfCommentIsEditable(
                                            a,
                                            currentUserId
                                        ) && (
                                            <div className="p-r-15">
                                                <Dropdown
                                                    trigger={({ onClick }) => (
                                                        <DotdotdotButton
                                                            disabled={
                                                                deleteComment.isPending
                                                            }
                                                            onClick={onClick}
                                                        />
                                                    )}
                                                >
                                                    {(dismiss) => (
                                                        <DropdownContent>
                                                            <SelectBasicItem
                                                                onClick={() => {
                                                                    dismiss()
                                                                    setEditedCommentId(
                                                                        a.id
                                                                    )
                                                                    setEditedCommentContent(
                                                                        a.comment ??
                                                                            ''
                                                                    )
                                                                }}
                                                            >
                                                                <Icon name="edit" />
                                                                Edit
                                                            </SelectBasicItem>
                                                            <SelectBasicItem
                                                                disabled={
                                                                    deleteComment.isPending
                                                                }
                                                                onClick={async () => {
                                                                    dismiss()
                                                                    try {
                                                                        await confirmModal.pop(
                                                                            {
                                                                                title: 'Delete comment',
                                                                                content:
                                                                                    'Are you sure you want to delete this comment?',
                                                                                onResolve:
                                                                                    () => {
                                                                                        deleteComment.mutate(
                                                                                            {
                                                                                                commentId:
                                                                                                    a.id,
                                                                                                answerId,
                                                                                            }
                                                                                        )
                                                                                    },
                                                                                confirmButtonText:
                                                                                    'Delete',
                                                                                cancelButtonText:
                                                                                    'Cancel',
                                                                            }
                                                                        )
                                                                    } catch (_) {
                                                                        // do nothing
                                                                    }
                                                                }}
                                                            >
                                                                <Icon name="trash" />
                                                                Delete
                                                            </SelectBasicItem>
                                                        </DropdownContent>
                                                    )}
                                                </Dropdown>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </SimpleActivityContent>
                        {a.type === AnswerActivityType.Comment &&
                            editedCommentId !== a.id && (
                                <CommentDisplay>{a.comment}</CommentDisplay>
                            )}
                    </ActivityItem>
                ))}
            </ActivityList>
            {hasNextPage && (
                <LoadMoreButton
                    isLoading={isFetchingNextPage}
                    onClick={fetchNextPage}
                />
            )}

            <div>
                <StyledTextAreaField
                    value={comment ?? ''}
                    placeholder="Leave a comment..."
                    onChange={(e) => setComment(e.target.value)}
                    containerClassName="relative"
                    disabled={createComment.isPending}
                    bottomContent={
                        <CommentButtonContainer>
                            <AddCommentButton
                                onClick={handleCreateComment}
                                disabled={!comment || createComment.isPending}
                            >
                                <Icon
                                    name={
                                        createComment.isPending
                                            ? 'spinner'
                                            : 'upArrow'
                                    }
                                />
                            </AddCommentButton>
                        </CommentButtonContainer>
                    }
                />
            </div>
        </div>
    )
}
