import React, { useMemo } from 'react'
import styled from 'styled-components'
import { themeVariables } from '../themes/themeVariables'

const colors = [
    '#a3acba',
    '#6a7383',
    '#30313d',
    '#00ade0',
    '#5faf26',
    '#4746f3',
    '#fbb215',
    '#e5512c',
    '#df1b41',
]

const UserAvatarPlaceholderWrapper = styled.div<{
    $colorIndex: number
    $size: number
}>`
    width: ${({ $size }) => $size}px;
    height: ${({ $size }) => $size}px;
    border-radius: 50%;
    background-color: ${({ $colorIndex }) => colors[$colorIndex]};
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${({ $size }) => $size / 2}px;
    line-height: ${({ $size }) => $size / 4}px;
    color: ${themeVariables.colors.backgroundSurface};
`

function initials(name: string): string {
    const letters = [...name.matchAll(/(^|\s+)(?<initial>\w)/g)].map(
        ({ groups = {} }) => groups.initial?.toUpperCase()
    )
    letters.splice(1, letters.length - 2)
    return letters.join('')
}

export const UserAvatarPlaceholder = ({
    userName,
    size = 16,
}: {
    userName: string
    size?: number
}) => {
    const userInitials = useMemo(() => {
        if (!userName) return ''
        return initials(userName)
    }, [userName])

    const colorIndex = useMemo(() => {
        const charCodeSum = userInitials
            .split('')
            .reduce((sum, char) => sum + char.charCodeAt(0), 0)
        return charCodeSum % colors.length
    }, [userInitials])
    return (
        <UserAvatarPlaceholderWrapper $colorIndex={colorIndex} $size={size}>
            {userInitials}
        </UserAvatarPlaceholderWrapper>
    )
}
