import React, { useEffect } from 'react'
import { Toolbar } from 'components/Toolbar'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { useRequiredParam } from 'utils/misc'
import {
    invalidateDataRoomDocumentsQuery,
    invalidateProjectQuery,
    useProjectQuery,
} from 'utils/queries'
import { route } from 'utils/routes'
import { ProjectInnerLayout } from '../components/ProjectPageLayout'
import { NavTabs } from '../components/NavTabs'
import { ProjectDataRoomDocuments } from '../components/ProjectDataRoomDocuments'
import DataRoomDocumentUploader from '../components/DataRoomDocumentUploader'
import { Separator } from '../components/Separator'
import { useUpdateProjectFocusedDocument } from '../state/ProjectFocusedDocument.state'

const Root = styled.div`
    display: grid;
    gap: 48px;
    padding: 40px 40px 96px;
`

function ProjectDataRoomShowPage() {
    const projectId = useRequiredParam('id')
    const { data: project } = useProjectQuery({ projectId })

    const updateFocusedDocument = useUpdateProjectFocusedDocument()

    useEffect(() => {
        return () => {
            updateFocusedDocument(null)
        }
    }, [])

    return (
        <ProjectInnerLayout
            projectId={projectId}
            toolbars={
                <Toolbar>
                    <Toolbar $grow>
                        <NavTabs>
                            <NavLink to={route('project', projectId)} end>
                                <small>Overview</small>
                            </NavLink>
                            <NavLink to={route('projectDataRoom', projectId)}>
                                <small>Data Room</small>
                            </NavLink>
                        </NavTabs>
                    </Toolbar>
                </Toolbar>
            }
        >
            <Root>
                {project && project.dataRoom.id && (
                    <DataRoomDocumentUploader
                        dataRoomId={project.dataRoom.id}
                        onDocumentAdded={() => {
                            invalidateProjectQuery(project.id)
                            invalidateDataRoomDocumentsQuery(project.dataRoomId)
                        }}
                    />
                )}

                {project && project.dataRoom._count.documents > 0 && (
                    <>
                        <Separator />
                        <ProjectDataRoomDocuments
                            projectId={projectId}
                            tableTitle="All documents"
                        />
                    </>
                )}
            </Root>
        </ProjectInnerLayout>
    )
}

export default ProjectDataRoomShowPage
