import { useMemo } from 'react'
import {
    keepPreviousData,
    useInfiniteQuery,
    useIsFetching,
    useQuery,
} from '@tanstack/react-query'
import {
    GenericListParams,
    ListDataRoomDocumentsParams,
    ListTeamInvitesParams,
    PermissionRole,
    ResourcePointer,
} from 'silta-ai-backend'
import { apiClient, queryClient } from './clients'

const DEFAULT_STALE_TIME = 5 * 60000 // 5 minutes
const DEFAULT_PAGE_SIZE = 100

function createListQuery<
    ListFn extends (params: GenericListParams) => Promise<unknown[]>,
>(queryKey: string, apiClientListFn: ListFn) {
    const boundListFn = apiClientListFn.bind(apiClient)

    return (params: Parameters<ListFn>[0] & { enabled?: boolean }) => {
        // Don't pass an empty string to `search` to avoid the silly `?search=`
        if (params.search?.length === 0) {
            params.search = undefined
        }

        const pageSize = params.pageSize || DEFAULT_PAGE_SIZE

        const query = useInfiniteQuery({
            initialPageParam: 1,
            queryKey: [queryKey, params],
            queryFn: async ({ pageParam }) => {
                const list = await boundListFn({
                    ...params,
                    page: pageParam,
                    pageSize,
                })
                return {
                    list,
                    page: pageParam,
                }
            },
            getNextPageParam: ({ list, page }) => {
                return list.length === pageSize ? page + 1 : undefined
            },
            staleTime: DEFAULT_STALE_TIME,
            enabled: params.enabled,
        })

        const list = useMemo(() => {
            return query.data?.pages.flatMap((page) => page.list) || []
        }, [query.data])

        return {
            ...query,
            data: list as Awaited<ReturnType<ListFn>>,
        }
    }
}

export const useModelQuery = (id: string) =>
    useQuery({
        queryKey: ['useModelQuery', id],
        queryFn: () => apiClient.getModel(id),
        staleTime: DEFAULT_STALE_TIME,
    })

export const useModel = (id: string) => useModelQuery(id).data

export const useIsFetchingAssessmentsForProject = (projectId?: string) =>
    useIsFetching({ queryKey: ['useAssessmentsQuery', { projectId }] }) > 0

interface UseAssessmentQueryParams {
    assessmentId: string | undefined
}

export const useAssessmentQuery = (params: UseAssessmentQueryParams) => {
    const { assessmentId } = params

    return useQuery({
        queryKey: ['useAssessmentQuery', assessmentId],
        queryFn: async () => {
            if (!assessmentId) {
                return null
            }

            return apiClient.getAssessment(assessmentId)
        },
        staleTime: DEFAULT_STALE_TIME,
    })
}

export const invalidateModelQuery = (modelId: string | undefined) => {
    if (modelId == null) {
        return queryClient.invalidateQueries({
            queryKey: ['useModelQuery'],
            exact: false,
        })
    }
    return queryClient.invalidateQueries({
        queryKey: ['useModelQuery', modelId],
        exact: true,
    })
}

export const invalidateAssessmentQuery = (assessmentId: string | undefined) => {
    if (assessmentId == null) {
        return queryClient.invalidateQueries({
            queryKey: ['useAssessmentQuery'],
            exact: false,
        })
    }

    return queryClient.invalidateQueries({
        queryKey: ['useAssessmentQuery', assessmentId],
        exact: true,
    })
}

export function useOutcomesQuery(modelId: string, params?: GenericListParams) {
    return useQuery({
        queryKey: ['useOutcomesQuery', modelId, params],
        queryFn: () => apiClient.getOutcomes({ modelId, ...(params || {}) }),
        staleTime: DEFAULT_STALE_TIME,
        placeholderData: keepPreviousData,
    })
}

export function invalidateOutcomesQuery(modelId: string) {
    return queryClient.invalidateQueries({
        queryKey: ['useOutcomesQuery', modelId],
        exact: false,
    })
}

export const useAssessmentsQuery = createListQuery(
    'useAssessmentsQuery',
    apiClient.getAssessments
)

export const useReportsQuery = createListQuery(
    'useReportsQuery',
    apiClient.getReports
)

export const useReportTemplatesQuery = createListQuery(
    'useReportTemplatesQuery',
    apiClient.getReportTemplates
)

export const usePermissionsQuery = createListQuery(
    'usePermissionsQuery',
    apiClient.getPermissions
)

export const useProjectsQuery = createListQuery(
    'useProjectsQuery',
    apiClient.getProjects
)

export const getUserPermissionsForResources = async (
    resourcePointers: ResourcePointer[],
    teamId?: string,
    invitedEmail?: string
): Promise<
    {
        resourceId: string
        role: PermissionRole | null
    }[]
> =>
    Promise.all(
        resourcePointers.map((resourcePointer) =>
            apiClient
                .getPermissions({
                    ...resourcePointer,
                    teamId,
                    invitedEmail,
                })
                .then((permissions) => ({
                    resourceId: Object.values(resourcePointer)[0] as string,
                    role: permissions[0]?.role ?? null,
                }))
        )
    )

export const useUserPermissionsForResourcesQuery = (
    resourcePointers: ResourcePointer[],
    teamId?: string,
    invitedEmail?: string
) => {
    return useQuery({
        queryKey: [
            'useUserPermissionsForResourcesQuery',
            { teamId, invitedEmail, resourcePointers },
        ],
        queryFn: () =>
            getUserPermissionsForResources(
                resourcePointers,
                teamId,
                invitedEmail
            ),
    })
}

export const invalidatePermissionsQuery = () => {
    return queryClient.invalidateQueries({
        queryKey: ['usePermissionsQuery'],
        exact: false,
    })
}

export const useDataRoomDocumentsQuery = createListQuery(
    'useDataRoomDocumentsQuery',
    apiClient.getDataRoomDocuments
)

export const invalidateDataRoomDocumentsQuery = (
    dataRoomId: string | undefined,
    params: Omit<ListDataRoomDocumentsParams, 'dataRoomId'> = {}
) => {
    if (dataRoomId == null) {
        return queryClient.invalidateQueries({
            queryKey: ['useDataRoomDocumentsQuery'],
            exact: false,
        })
    }

    return queryClient.invalidateQueries({
        queryKey: ['useDataRoomDocumentsQuery', { dataRoomId, ...params }],
        exact: true,
    })
}

export const useTeamPermissionsForResourceQuery = (
    resourcePointer: ResourcePointer
) => {
    return useQuery({
        queryKey: ['useCheckTeamPermissionsForResourceQuery', resourcePointer],
        queryFn: async () =>
            (await apiClient.getPermissionForResource(resourcePointer))?.role ??
            null,
    })
}

export const useTeamPermissionsForAllResourcesQuery = (
    resourcePointers?: ResourcePointer[]
) => {
    return useQuery<Record<string, PermissionRole | null>>({
        queryKey: ['useCheckTeamPermissionsForResourceQuery', resourcePointers],
        queryFn: async () => {
            if (!resourcePointers) {
                return {}
            }

            const permissions = await Promise.all(
                resourcePointers.map((resourcePointer) =>
                    apiClient
                        .getPermissionForResource(resourcePointer)
                        .then((permission) => ({
                            resourcePointer,
                            permission: permission?.role ?? null,
                        }))
                )
            )

            return permissions.reduce(
                (
                    acc: Record<string, PermissionRole | null>,
                    { resourcePointer, permission }
                ) => {
                    const resourceId = Object.values(
                        resourcePointer
                    )[0] as string
                    acc[resourceId] = permission
                    return acc
                },
                {}
            )
        },
    })
}

interface UseProjectQueryParams {
    projectId: string | undefined
}

export const useProjectQuery = (params: UseProjectQueryParams) => {
    const { projectId } = params

    return useQuery({
        queryKey: ['useProjectQuery', projectId],
        queryFn: () => {
            if (!projectId) {
                return null
            }

            return apiClient.getProject(projectId)
        },
        staleTime: DEFAULT_STALE_TIME,
    })
}

export const invalidateProjectQuery = (projectId: string | undefined) => {
    if (projectId == null) {
        return queryClient.invalidateQueries({
            queryKey: ['useProjectQuery'],
            exact: false,
        })
    }

    return queryClient.invalidateQueries({
        queryKey: ['useProjectQuery', projectId],
        exact: true,
    })
}

export const useDataRoomQuery = (dataRoomId: string) => {
    return useQuery({
        queryKey: ['useDataRoomQuery', dataRoomId],
        queryFn: () => apiClient.getDataRoom(dataRoomId),
        staleTime: DEFAULT_STALE_TIME,
    })
}

export const invalidateDataRoomQuery = (dataRoomId: string | undefined) => {
    if (dataRoomId == null) {
        return queryClient.invalidateQueries({
            queryKey: ['useDataRoomQuery'],
            exact: false,
        })
    }

    return queryClient.invalidateQueries({
        queryKey: ['useDataRoomQuery', dataRoomId],
        exact: true,
    })
}

export const useDataRoomsQuery = createListQuery(
    'useDataRoomsQuery',
    apiClient.getDataRooms
)

export const useModelsQuery = createListQuery(
    'useModelsQuery',
    apiClient.getModels
)

export const useUsersQuery = createListQuery(
    'useUsersQuery',
    apiClient.getUsers
)

export const useTeamsQuery = createListQuery(
    'useTeamsQuery',
    apiClient.getTeams
)

export const useTeamQuery = (teamId?: string) => {
    return useQuery({
        queryKey: ['useTeamQuery', teamId],
        queryFn: () => {
            if (!teamId) {
                return null
            }
            return apiClient.getTeam(teamId)
        },
        staleTime: DEFAULT_STALE_TIME,
    })
}

export const invalidateTeamQuery = (teamId: string | undefined) => {
    if (!teamId) {
        return queryClient.invalidateQueries({
            queryKey: ['useTeamQuery'],
            exact: false,
        })
    }
    return queryClient.invalidateQueries({
        queryKey: ['useTeamQuery', teamId],
        exact: true,
    })
}

export const useTeamInvitesQuery = createListQuery(
    'useTeamInvitesQuery',
    apiClient.getTeamInvites
)

export const invalidateTeamInvitesQuery = (params?: ListTeamInvitesParams) => {
    if (!params) {
        return queryClient.invalidateQueries({
            queryKey: ['useTeamInvitesQuery', {}],
            exact: false,
        })
    }
    return queryClient.invalidateQueries({
        queryKey: ['useTeamInvitesQuery', params],
        exact: true,
    })
}

export const invalidateTeamMembersAndIvitesQuery = (
    teamId: string | undefined
) => {
    if (!teamId) {
        return queryClient.invalidateQueries({
            queryKey: ['useTeamMembersAndInvitesQuery'],
            exact: false,
        })
    }
    return queryClient.invalidateQueries({
        queryKey: ['useTeamMembersAndInvitesQuery', teamId],
        exact: true,
    })
}

interface UseCurrentUserQueryParams {
    enabled?: boolean
}

export const useCurrentUserQuery = ({
    enabled = true,
}: UseCurrentUserQueryParams = {}) =>
    useQuery({
        queryKey: ['useCurrentUserQuery'],
        queryFn: () => apiClient.getMyProfile(),
        staleTime: DEFAULT_STALE_TIME,
        enabled,
    })

export const invalidateCurrentUserQuery = () => {
    return queryClient.invalidateQueries({
        queryKey: ['useCurrentUserQuery'],
        exact: false,
    })
}

interface UseAnswerQueryParams {
    answerId: string | undefined
}

export const useAnswerQuery = (params: UseAnswerQueryParams) => {
    const { answerId } = params

    return useQuery({
        queryKey: ['useAnswerQuery', answerId],
        queryFn: async () => {
            if (!answerId) {
                return null
            }

            return apiClient.getAnswer(answerId)
        },
        staleTime: DEFAULT_STALE_TIME,
    })
}

export const invalidateAnswerQuery = (answerId: string | undefined) => {
    if (!answerId) {
        return queryClient.invalidateQueries({
            queryKey: ['useAnswerQuery'],
            exact: false,
        })
    }

    return queryClient.invalidateQueries({
        queryKey: ['useAnswerQuery', answerId],
        exact: true,
    })
}

export const useReportTemplateQuery = (reportTemplateId: string) =>
    useQuery({
        queryKey: ['useReportTemplateQuery', reportTemplateId],
        queryFn: () => apiClient.getReportTemplate(reportTemplateId),
    })

export const invalidateReportTemplateQuery = (reportTemplateId: string) => {
    return queryClient.invalidateQueries({
        queryKey: ['useReportTemplateQuery', reportTemplateId],
        exact: true,
    })
}

export const invalidateReportTemplatesQuery = () => {
    return queryClient.invalidateQueries({
        queryKey: ['useReportTemplatesQuery'],
    })
}

export const useReportQuery = (reportId: string) =>
    useQuery({
        queryKey: ['useReportQuery', reportId],
        queryFn: () => apiClient.getReport(reportId),
    })

export const invalidateReportQuery = (reportId: string) => {
    return queryClient.invalidateQueries({
        queryKey: ['useReportQuery', reportId],
        exact: true,
    })
}

export const useCategoryAssigneesQuery = (assessmentId: string) => {
    return useQuery({
        queryKey: ['useCategoryAssigneesQuery', assessmentId],
        queryFn: () => apiClient.getCategoryAssignees({ assessmentId }),
    })
}

export const invalidateCategoryAssigneesQuery = (assessmentId: string) => {
    return queryClient.invalidateQueries({
        queryKey: ['useCategoryAssigneesQuery', assessmentId],
    })
}

export const useSignupCodeQuery = (signupCode: string) => {
    return useQuery({
        queryKey: ['useSignupCodeQuery', signupCode],
        queryFn: () => apiClient.getSignupCode(signupCode),
        retry: false,
    })
}

export const useAnswerActivitiesQuery = (answerId?: string) => {
    const pageSize = DEFAULT_PAGE_SIZE
    return useInfiniteQuery({
        queryKey: ['useAnswerActivitiesQuery', answerId],
        initialPageParam: 1,
        queryFn: async ({ pageParam }: { pageParam: number }) => {
            if (!answerId) return { page: 1, activities: [] }
            const activities = await apiClient.getAnswerActivities({
                answerId,
                page: pageParam,
                pageSize,
            })
            return {
                page: pageParam,
                activities: activities.map((activity) => ({
                    ...activity,
                    createdAt: new Date(activity.createdAt),
                    updatedAt: new Date(activity.updatedAt),
                })),
            }
        },
        getNextPageParam: ({ activities, page }) => {
            return activities.length === pageSize ? page + 1 : undefined
        },
        staleTime: DEFAULT_STALE_TIME,
    })
}

export const useIsFetchingAnswerActivities = (answerId?: string) => {
    return (
        useIsFetching({ queryKey: ['useAnswerActivitiesQuery', answerId] }) > 0
    )
}

export const invalidateAnswerActivitiesQuery = (answerId?: string) => {
    if (!answerId) {
        return queryClient.invalidateQueries({
            queryKey: ['useAnswerActivitiesQuery'],
            exact: false,
        })
    }

    return queryClient.invalidateQueries({
        queryKey: ['useAnswerActivitiesQuery', answerId],
        exact: true,
    })
}

export const useIsMutatingAnswerComment = () => {
    const isCreating =
        useIsFetching({ queryKey: ['useCreateAnswerComment'] }) > 0
    const isUpdating =
        useIsFetching({ queryKey: ['useUpdateAnswerComment'] }) > 0
    const isDeleting =
        useIsFetching({ queryKey: ['useDeleteAnswerComment'] }) > 0
    return isCreating || isUpdating || isDeleting
}

export const resetAllQueries = () => {
    return queryClient.resetQueries()
}
