import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import cx from 'classnames'
import { Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import { useAuth } from '../providers/AuthProvider'
import { ErrorAlert } from '../components/ErrorAlert'
import { Button } from '../components/Button'
import BackgroundVector from '../assets/img/backgroundVector.svg'
import { Logo } from '../components/Logo'
import { themeVariables } from '../themes/themeVariables'
import { TextField } from '../components/Form/TextField'
import { Icon } from '../components/Icon'
import { InfoBox } from '../components/InfoBox'
import { route } from '../utils/routes'

const validationSchema = yup.object({
    email: yup
        .string()
        .email('Invalid email format')
        .required('Email is required'),
    password: yup
        .string()
        .min(8, 'Password should be at least 8 characters')
        .required('Password is required'),
})

type FormValues = yup.InferType<typeof validationSchema>

const Background = styled.div`
    background-color: ${themeVariables.colors.backgroundSurface};
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url(${BackgroundVector});
    background-repeat: no-repeat;
    background-size: contain;
    padding: 20px;
`

const Frame = styled.div`
    max-width: 538px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const LoginContainer = styled.div`
    margin-top: 65px;
    background-color: ${themeVariables.colors.backgroundSurface};
    padding: 60px;
    border-radius: 8px;
    width: 100%;
    border: 1px solid ${themeVariables.colors.border};
    box-shadow:
        0px 3px 6px 0px #0000001f,
        0px 7px 14px 0px #30313d14;
`

/* const InfoBox = styled.div`
    background-color: ${themeVariables.colors.backgroundSurface};
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    border: 1px solid ${themeVariables.colors.border};
    margin-top: 32px;
    display: flex;
    gap: 25px;
`

const LockIcon = styled(Icon)`
    width: 14px;
    height: 14px;
    flex-shrink: 0;
    color: ${themeVariables.palettes.neutral700};
` */

const FooterLinks = styled.div`
    display: flex;
    gap: 26px;
    margin-top: 32px;
    color: ${themeVariables.palettes.neutral700};
    width: 100%;
    padding: 0 20px;

    a,
    a:focus,
    a:hover,
    a:visited {
        color: ${themeVariables.palettes.neutral700};
        cursor: pointer;
    }

    > * {
        position: relative;
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: -14px;
            width: 3px;
            height: 3px;
            border-radius: 3px;
            background-color: ${themeVariables.palettes.neutral700};
        }

        &:last-child::after {
            display: none;
        }
    }
`

const ShowPasswordButton = styled.button`
    color: ${themeVariables.palettes.neutral500};
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
`

export const Login = () => {
    const auth = useAuth()
    const navigate = useNavigate()
    const { state } = useLocation()
    const [showPassword, setShowPassword] = useState(false)

    return (
        <Background>
            <Frame>
                <Logo />
                <LoginContainer>
                    <h1 className="m-b-40">Sign in to your account</h1>
                    {auth.loginError && <ErrorAlert error={auth.loginError} />}
                    <Formik
                        initialValues={{ email: '', password: '' }}
                        onSubmit={async (
                            values: FormValues,
                            { setSubmitting }: FormikHelpers<FormValues>
                        ) => {
                            await auth.signin(values)
                            setSubmitting(false)
                            if (state) {
                                navigate(state.from)
                            } else {
                                navigate(route('home'))
                            }
                        }}
                        validationSchema={validationSchema}
                    >
                        {({
                            values,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            touched,
                            errors,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    allowOnePassword
                                    id="formEmail"
                                    type="text"
                                    name="email"
                                    placeholder="Enter your email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    label="Email"
                                    errorMessage={
                                        touched.email && !!errors.email
                                            ? errors.email
                                            : undefined
                                    }
                                    containerClassName="m-b-25"
                                />
                                <TextField
                                    allowOnePassword
                                    id="formPassword"
                                    type={showPassword ? 'text' : 'password'}
                                    name="password"
                                    placeholder="Enter your password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values.password}
                                    label="Password"
                                    errorMessage={
                                        touched.password && !!errors.password
                                            ? errors.password
                                            : undefined
                                    }
                                    additionalInnerContent={
                                        <ShowPasswordButton
                                            type="button"
                                            onClick={() =>
                                                setShowPassword(!showPassword)
                                            }
                                        >
                                            <Icon
                                                className="secondary"
                                                name={
                                                    showPassword
                                                        ? 'eyeOff'
                                                        : 'eye'
                                                }
                                            />
                                        </ShowPasswordButton>
                                    }
                                />
                                <div>
                                    <Button
                                        type="submit"
                                        $variant="primary"
                                        $size="large"
                                        className={cx('w-100', 'm-t-35')}
                                    >
                                        Sign in
                                    </Button>
                                </div>
                            </form>
                        )}
                    </Formik>
                </LoginContainer>
                <InfoBox
                    iconName="lock"
                    backgroundColor={themeVariables.colors.backgroundSurface}
                    borderColor={themeVariables.colors.border}
                    textColor={themeVariables.colors.primary}
                    iconColor={themeVariables.palettes.neutral700}
                    className="m-t-30"
                >
                    On a shared computer, make sure to sign out when you&apos;re
                    done. This helps keep your account secure from other people
                    using your device.
                </InfoBox>
                <FooterLinks>
                    <span>© ADB</span>
                    <Link to="/contact">Contact</Link>
                    <Link to="/privacy">Privacy & terms</Link>
                </FooterLinks>
            </Frame>
        </Background>
    )
}
