import React from 'react'
import { QueryClientProvider } from '@tanstack/react-query'
import { AssessmentPageLayout } from 'components/AssessmentPageLayout'
import { AssessmentShowAnswersPage } from 'pages/AssessmentShowAnswers'
import { AssessmentShowReportsPage } from 'pages/AssessmentShowReports'
import { AssessmentShowSummaryPage } from 'pages/AssessmentShowSummary'
import { createRoot } from 'react-dom/client'
import {
    Navigate,
    createBrowserRouter,
    RouterProvider,
    Outlet,
} from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { Container } from 'toasterhea'
import 'reset-css'
import { FilterProvider } from 'utils/filters'
import { queryClient } from 'utils/clients'
import { PrivateRoute } from './components/PrivateRoute'
import { ScrollToTop } from './components/ScrollToTop'
import { AccActivate } from './pages/AccActivate'
import { AnswerShowPage } from './pages/AnswerShow'
import AssessmentCreatePage from './pages/AssessmentCreate/AssessmentCreate'
import { AssessmentCreateQuestions } from './pages/AssessmentCreate/AssessmentCreateQuestions'
import { AssessmentDraftPage } from './pages/AssessmentCreate/AssessmentDraftPage'
import AssessmentListPage from './pages/AssessmentList'
import DataRoomListPage from './pages/DataRoomList'
import DataRoomShowPage from './pages/DataRoomShow'
import { Forgot } from './pages/Forgot'
import { Login } from './pages/Login'
import { ModelListPage } from './pages/ModelList'
import { ModelShowPage } from './pages/ModelShow'
import ProjectCreatePage from './pages/ProjectCreate'
import ProjectListPage from './pages/ProjectList'
import ProjectShowPage from './pages/ProjectShow'
import { ReportListPage } from './pages/ReportList'
import { ReportShowPage } from './pages/ReportShow'
import { ReportTemplateListPage } from './pages/ReportTemplateList'
import { ReportTemplateShowPage } from './pages/ReportTemplateShow'
import { Reset } from './pages/Reset'
import { SignUpPage } from './pages/SignUpPage'
import { AuthProvider } from './providers/AuthProvider'
import { GlobalStyle } from './styles/globalStyle'
import './styles/styles.scss'
import { CURRENT_THEME } from './utils/currentTheme'
import { route } from './utils/routes'
import { Layer } from './utils/layers'
import { ProjectPageLayout } from './components/ProjectPageLayout'
import ProjectDataRoomShowPage from './pages/ProjectDataRoomShow'
import { TermsAndConditionsPage } from './pages/TermsAndConditionsPage'
import { TermsAndConditionsRoute } from './components/TermsAndConditionsRoute'
import { ApiClientTeamSetter } from './components/ApiClientTeamSetter'
import { ProfilePage } from './pages/ProfilePage'
import { HomePage } from './pages/HomePage'
import { TeamPage } from './pages/TeamPage'
import { ToastContainer } from './components/Toast/Toast'
import { AccessDeniedProvider } from './providers/AccessDeniedProvider'

import(`./themes/${CURRENT_THEME}/style.css`)

const root = createRoot(document.getElementById('root')!)

const router = createBrowserRouter([
    {
        element: (
            <>
                <FilterProvider>
                    <AuthProvider>
                        <AccessDeniedProvider>
                            <GlobalStyle />
                            <ApiClientTeamSetter />
                            <Outlet />
                            <Container id={Layer.Modal} />
                            <ToastContainer id={Layer.Toast} />
                            <ScrollToTop />
                        </AccessDeniedProvider>
                    </AuthProvider>
                </FilterProvider>
            </>
        ),
        children: [
            {
                element: <PrivateRoute />,
                children: [
                    {
                        path: route('termsAndConditions'),
                        element: <TermsAndConditionsPage />,
                    },
                    {
                        element: <TermsAndConditionsRoute />,
                        children: [
                            {
                                path: route('profile'),
                                element: <ProfilePage />,
                            },
                            { path: route('home'), element: <HomePage /> },
                            {
                                path: route('teamSettings'),
                                element: <TeamPage />,
                            },
                            {
                                element: <AssessmentDraftPage />,
                                children: [
                                    {
                                        path: route('assessmentCreate'),
                                        element: <AssessmentCreatePage />,
                                    },
                                    {
                                        path: route(
                                            'assessmentCreateQuestions'
                                        ),
                                        element: <AssessmentCreateQuestions />,
                                    },
                                ],
                            },
                            {
                                element: <AssessmentPageLayout />,
                                children: [
                                    {
                                        path: route('assessment', ':id'),
                                        element: <AssessmentShowSummaryPage />,
                                    },
                                    {
                                        path: route('assessmentAnswers', ':id'),
                                        element: <AssessmentShowAnswersPage />,
                                    },
                                    {
                                        path: route('assessmentReports', ':id'),
                                        element: <AssessmentShowReportsPage />,
                                    },
                                ],
                            },
                            {
                                path: route('assessments'),
                                element: <AssessmentListPage />,
                            },
                            {
                                path: route(
                                    'answer',
                                    ':assessmentId',
                                    ':answerId'
                                ),
                                element: <AnswerShowPage />,
                            },
                            {
                                path: route('dataRooms'),
                                element: <DataRoomListPage />,
                            },
                            {
                                path: route('dataRoom', ':id'),
                                element: <DataRoomShowPage />,
                            },
                            {
                                path: route('projects'),
                                element: <ProjectListPage />,
                            },
                            {
                                path: route('projectCreate'),
                                element: <ProjectCreatePage />,
                            },
                            {
                                element: <ProjectPageLayout />,
                                children: [
                                    {
                                        path: route('project', ':id'),
                                        element: <ProjectShowPage />,
                                    },
                                    {
                                        path: route('projectDataRoom', ':id'),
                                        element: <ProjectDataRoomShowPage />,
                                    },
                                ],
                            },
                            {
                                path: route('models'),
                                element: <ModelListPage />,
                            },
                            {
                                path: route('model', ':id'),
                                element: <ModelShowPage />,
                            },
                            {
                                path: route('reports'),
                                element: <ReportListPage />,
                            },
                            {
                                path: route('report', ':id'),
                                element: <ReportShowPage />,
                            },
                            {
                                path: route('reportTemplates'),
                                element: <ReportTemplateListPage />,
                            },
                            {
                                path: route('reportTemplate', ':id'),
                                element: <ReportTemplateShowPage />,
                            },
                        ],
                    },
                ],
            },
            { path: '/activate-account/:id', element: <AccActivate /> },
            { path: route('login'), element: <Login /> },
            { path: route('signUp'), element: <SignUpPage /> },
            { path: '/forgot-password', element: <Forgot /> },
            { path: '/password-reset/:token', element: <Reset /> },
            { path: '*', element: <Navigate to={route('home')} /> },
        ],
    },
])

root.render(
    <QueryClientProvider client={queryClient}>
        <RecoilRoot>
            <RouterProvider router={router} />
        </RecoilRoot>
    </QueryClientProvider>
)
