import React, { useEffect } from 'react'
import { Toolbar } from 'components/Toolbar'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { useRequiredParam } from 'utils/misc'
import { useProjectQuery } from 'utils/queries'
import { route } from 'utils/routes'
import { ProjectInnerLayout } from '../components/ProjectPageLayout'
import { NavTabs } from '../components/NavTabs'
import { themeVariables } from '../themes/themeVariables'
import { Icon } from '../components/Icon'
import { ProjectDataRoomDocuments } from '../components/ProjectDataRoomDocuments'
import { Separator } from '../components/Separator'
import { ProjectAssessments } from '../components/ProjectAssessments'
import { useUpdateProjectFocusedDocument } from '../state/ProjectFocusedDocument.state'

const Pad = styled.div`
    max-width: 778px;
`

const Root = styled.div`
    display: grid;
    gap: 48px;
    padding: 40px 40px 96px;
`

const ProjectCustomId = styled.p`
    margin-bottom: 8px;
    color: ${themeVariables.colors.secondary};
`

const ProejctTitle = styled.h1`
    font-weight: 600;
    margin-bottom: 24px;
`

export const TileGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(192px, 1fr));
    gap: 16px;
`

const SingelTile = styled.div`
    background: ${themeVariables.palettes.neutral200};
    border-radius: 8px;
    display: flex;
    align-items: flex-start;
    min-width: 100px;
    padding: 24px;
    gap: 24px;
`

const TileIcon = styled(Icon)`
    color: ${themeVariables.colors.secondary};
    width: 20px;
    height: 20px;
    svg {
        width: 20px;
        height: 20px;
    }
`

const TileTitle = styled.p`
    font-weight: ${themeVariables.typography.fontWeight.emphasized};
    margin-bottom: 12px;
`

const TileContent = styled.div`
    min-height: 40px;
`

function ProjectShowPage() {
    const projectId = useRequiredParam('id')

    const { data: project = null } = useProjectQuery({ projectId })

    const updateFocusedDocument = useUpdateProjectFocusedDocument()

    useEffect(() => {
        return () => {
            updateFocusedDocument(null)
        }
    }, [])

    return (
        <ProjectInnerLayout
            projectId={projectId}
            toolbars={
                <Toolbar>
                    <Toolbar $grow>
                        <NavTabs>
                            <NavLink to={route('project', projectId)} end>
                                <small>Overview</small>
                            </NavLink>
                            <NavLink to={route('projectDataRoom', projectId)}>
                                <small>Data Room</small>
                            </NavLink>
                        </NavTabs>
                    </Toolbar>
                </Toolbar>
            }
        >
            <Root>
                <Pad>
                    {project?.customId && (
                        <ProjectCustomId>{project.customId}</ProjectCustomId>
                    )}
                    <ProejctTitle>{project?.name}</ProejctTitle>
                    <div>{project?.description}</div>
                </Pad>
                <TileGrid>
                    {project?.country && (
                        <SingelTile>
                            <TileIcon name="globe" />
                            <div>
                                <TileTitle>Country</TileTitle>
                                <TileContent>{project.country}</TileContent>
                            </div>
                        </SingelTile>
                    )}
                    {project?.assignedTo && (
                        <SingelTile>
                            <TileIcon name="userCircle" />
                            <div>
                                <TileTitle>Project Officer</TileTitle>
                                <TileContent>
                                    {project.assignedTo.name}
                                </TileContent>
                            </div>
                        </SingelTile>
                    )}
                    {project?.sector && (
                        <SingelTile>
                            <TileIcon name="parthenon" />
                            <div>
                                <TileTitle>Sector</TileTitle>
                                <TileContent>{project.sector}</TileContent>
                            </div>
                        </SingelTile>
                    )}
                </TileGrid>

                <Separator />

                <ProjectDataRoomDocuments
                    projectId={projectId}
                    tableTitle="Latest documents"
                    limit={3}
                    showUploadButton={true}
                    hideLoadMoreButton={true}
                />
                <ProjectAssessments projectId={projectId} />
            </Root>
        </ProjectInnerLayout>
    )
}

export default ProjectShowPage
