import React from 'react'
import cx from 'classnames'
import { useNavigate } from 'react-router'
import { AssessmentListItem } from 'silta-ai-backend'
import { useAssessmentsQuery } from '../utils/queries'
import { Button } from './Button'
import { route } from '../utils/routes'
import { Icon } from './Icon'
import {
    Table,
    TableNoContentContainer,
    TableNoContentIcon,
    TableNoContentIconContainer,
} from './Table'
import { DateRenderer } from './renderers/DateRenderer'
import { AssessmentProgress } from './AssessmentProgress'
import { TableDataLoading } from './TableDataLoading'
import { LoadMoreButton } from './LoadMoreButton'

export const ProjectAssessments = ({
    projectId,
    className,
}: {
    projectId: string
    className?: string
}) => {
    const {
        data: assessments = [],
        isLoading,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
    } = useAssessmentsQuery({
        projectId,
    })
    const navigate = useNavigate()
    return (
        <div className={className}>
            <div
                className={cx(
                    'd-flex',
                    'align-items-center',
                    'justify-content-between',
                    'm-b-30'
                )}
            >
                <h3>Assessments</h3>
                {assessments.length > 0 && (
                    <div className={cx('d-flex', 'g-5')}>
                        <Button
                            $variant="secondary"
                            $size="small"
                            onClick={() =>
                                navigate(route('assessmentCreate', projectId))
                            }
                        >
                            Create assessment
                        </Button>
                    </div>
                )}
            </div>
            {assessments.length > 0 && (
                <Table
                    onRowClick={(assessment) =>
                        navigate(route('assessment', assessment.id))
                    }
                    columns={[
                        {
                            key: 'name',
                            title: 'Name',
                            width: 'minmax(min-content, 10%)',
                        },
                        {
                            key: 'projectId',
                            title: 'Project ID',
                            render: (assessment: AssessmentListItem) =>
                                assessment.project.customId,
                        },
                        {
                            key: 'projectName',
                            title: 'Project Name',
                            render: (assessment: AssessmentListItem) =>
                                assessment.project.name,
                        },
                        {
                            key: 'evaluationCriteria',
                            title: 'Evaluation Criteria',
                            render: (assessment: AssessmentListItem) =>
                                assessment.model?.name,
                        },
                        {
                            key: 'createdAt',
                            title: 'Created',
                            render: ({ createdAt }) => (
                                <DateRenderer value={createdAt} />
                            ),
                        },
                        {
                            key: 'progress',
                            title: 'Progress',
                            render: (assessment: AssessmentListItem) => (
                                <AssessmentProgress
                                    assessment={assessment}
                                    showPercentage
                                />
                            ),
                        },
                    ]}
                    data={assessments}
                    noHorizontalPadding
                />
            )}
            {isLoading && <TableDataLoading />}
            {assessments.length === 0 && !isLoading && (
                <>
                    <TableNoContentContainer>
                        <TableNoContentIconContainer>
                            <TableNoContentIcon name="pieChart" />
                        </TableNoContentIconContainer>
                        <div className={cx('large', 'emphasized')}>
                            Create Assessment
                        </div>
                        <div className="secondary">
                            Assessments are Evaluation Criteria applied to a
                            Project.
                        </div>
                        <Button
                            onClick={() =>
                                navigate(route('assessmentCreate', projectId))
                            }
                            className={cx('d-flex', 'g-5', 'm-t-25')}
                        >
                            <Icon name="upload" />
                            Create Assessment
                        </Button>
                    </TableNoContentContainer>
                </>
            )}
            {hasNextPage && (
                <LoadMoreButton
                    onClick={fetchNextPage}
                    isLoading={isFetchingNextPage}
                />
            )}
        </div>
    )
}
