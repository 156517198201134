import React, { useCallback, useMemo, useState } from 'react'
import { DataRoomDocument } from 'silta-ai-backend'
import styled from 'styled-components'
import cx from 'classnames'
import { toaster } from 'toasterhea'
import { useRequiredParam } from 'utils/misc'
import { downloadDocument } from 'utils/download'
import { AccessDeniedHandler } from 'components/AccessDeniedHandler'
import DataRoomDocumentUploader from '../components/DataRoomDocumentUploader'
import { Layout } from '../components/Layout'
import { ListTopBar } from '../components/ListTopBar'
import { HorizontalLoadingIndicator } from '../components/HorizontalLoadingIndicator'
import {
    invalidateDataRoomQuery,
    useTeamPermissionsForResourceQuery,
    useDataRoomDocumentsQuery,
    useDataRoomQuery,
    invalidateDataRoomDocumentsQuery,
} from '../utils/queries'
import { BreadcrumbsToolbar } from '../components/Toolbar/BreadcrumbsToolbar'
import { route } from '../utils/routes'
import { DotdotdotButton } from '../components/DotdotdotButton'
import { Dropdown, DropdownContent } from '../components/Dropdown'
import {
    SelectBasicItem,
    SelectBasicItemIconWrap,
} from '../components/Form/Select/SelectItemRenderer'
import { Icon } from '../components/Icon'
import { Separator } from '../components/Separator'
import { Table } from '../components/Table'
import { FileTypeIcon } from '../components/FileTypeIcon'
import { DateRenderer } from '../components/renderers/DateRenderer'
import { ConfirmationModal } from '../components/modals/ConfirmationModal'
import { Layer } from '../utils/layers'
import {
    useDeleteDataRoom,
    useDeleteDocument,
    useUpdateDataRoom,
} from '../utils/mutations'
import { Tooltip } from '../components/Tooltip'
import { useShareResourceModal } from '../components/modals/ShareResourceModal'
import { useNameInputModal } from '../components/modals/NameInputModal'
import { LoadMoreButton } from '../components/LoadMoreButton'
import { ShowSidebarProvider, Sidebar } from '../components/Sidebar'
import { SidebarToggle } from '../components/SidebarToggle'
import { DocumentSidebarContent } from '../components/DocumentSidebarContent'

const Pad = styled.div`
    padding: 40px;
    flex: 1;
`

const DropdownIcon = styled(Icon)`
    width: 16px;
    height: 16px;
    svg {
        width: 16px;
        height: 16px;
    }
`
const MainContainer = styled.div`
    min-height: 100%;
    display: flex;
    flex-direction: column;
`

const ContentWithSidebarContainer = styled.div`
    display: flex;
    flex: 1;
`

export default function DataRoomShowPage() {
    const id = useRequiredParam('id')
    const [focusedDocument, setFocusedDocument] =
        useState<DataRoomDocument | null>(null)
    const { data: dataRoom, isFetching } = useDataRoomQuery(id)
    const {
        data: documents,
        isFetching: isFetchingDocuments,
        isFetchingNextPage,
        hasNextPage,
        fetchNextPage,
    } = useDataRoomDocumentsQuery({ dataRoomId: id })
    const deleteDocument = useDeleteDocument()
    const deleteDataRoom = useDeleteDataRoom()

    const confirmModal = toaster(ConfirmationModal, Layer.Modal)

    const triggerDeletePecedentDatabase = async () => {
        try {
            const confirm = await confirmModal.pop({
                title: 'Delete document',
                content: `Are you sure you want to delete data room "${dataRoom?.name}"?`,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
            })
            if (confirm) {
                deleteDataRoom.mutate(id)
            }
        } catch (_) {
            // do nothing
        }
    }

    const triggerDeleteDocument = async (document: DataRoomDocument) => {
        try {
            const confirm = await confirmModal.pop({
                title: 'Delete document',
                content: `Are you sure you want to delete document "${document.originalFileName}"?`,
                confirmButtonText: 'Delete',
                cancelButtonText: 'Cancel',
            })
            if (confirm) {
                deleteDocument.mutate(
                    {
                        documentId: document.id,
                        dataRoomId: id,
                    },
                    {
                        onSuccess: () => {
                            invalidateDataRoomQuery(id)
                            invalidateDataRoomDocumentsQuery(id)
                            if (document.id === focusedDocument?.id) {
                                setFocusedDocument(null)
                            }
                        },
                    }
                )
            }
        } catch (_) {
            // do nothing
        }
    }

    const { data: permission, isFetching: teamPermissionIsLoading } =
        useTeamPermissionsForResourceQuery({
            dataRoomId: id,
        })

    const shareResouceModal = useShareResourceModal()

    const triggerShareResource = useCallback(() => {
        if (!dataRoom) {
            return
        }
        shareResouceModal(dataRoom.name, { dataRoomId: dataRoom.id })
    }, [dataRoom, shareResouceModal])

    const updateDataRoom = useUpdateDataRoom()
    const openNameInputModal = useNameInputModal()

    const hoveredRowIndex = useMemo(() => {
        return documents.findIndex((d) => d.id === focusedDocument?.id)
    }, [documents, focusedDocument])

    return (
        <AccessDeniedHandler>
            <ShowSidebarProvider value={!!focusedDocument}>
                <Layout>
                    <MainContainer>
                        <ListTopBar />
                        <HorizontalLoadingIndicator
                            loading={
                                isFetching ||
                                isFetchingDocuments ||
                                deleteDocument.isPending ||
                                deleteDataRoom.isPending
                            }
                        />

                        <BreadcrumbsToolbar
                            currentPageName={dataRoom?.name || ''}
                            listPage={{
                                name: 'Precedent Databases',
                                route: route('dataRooms'),
                                icon: 'database',
                            }}
                            lhsAux={
                                <>
                                    <Dropdown
                                        trigger={({ onClick }) => (
                                            <DotdotdotButton
                                                onClick={onClick}
                                            />
                                        )}
                                    >
                                        {(dismiss) => (
                                            <DropdownContent>
                                                <Tooltip
                                                    content={
                                                        documents &&
                                                        documents.length > 0
                                                            ? 'You cannot delete a non-empty database'
                                                            : ''
                                                    }
                                                >
                                                    <SelectBasicItem
                                                        type="button"
                                                        disabled={
                                                            teamPermissionIsLoading ||
                                                            permission !==
                                                                'Owner'
                                                        }
                                                        onClick={() => {
                                                            dismiss()
                                                            triggerShareResource()
                                                        }}
                                                    >
                                                        <SelectBasicItemIconWrap>
                                                            <Icon name="users" />
                                                        </SelectBasicItemIconWrap>
                                                        Manage permissions
                                                    </SelectBasicItem>
                                                    <SelectBasicItem
                                                        type="button"
                                                        disabled={
                                                            teamPermissionIsLoading ||
                                                            permission ===
                                                                'Viewer'
                                                        }
                                                        onClick={() => {
                                                            dismiss()
                                                            openNameInputModal({
                                                                modalTitle:
                                                                    'Rename precedent database',
                                                                buttonCtaText:
                                                                    'Save',
                                                                buttonCtaSavingText:
                                                                    'Saving',
                                                                currentName:
                                                                    dataRoom?.name,
                                                                onConfirm:
                                                                    async (
                                                                        newName: string
                                                                    ) => {
                                                                        await updateDataRoom.mutateAsync(
                                                                            {
                                                                                id,
                                                                                name: newName,
                                                                            }
                                                                        )
                                                                    },
                                                            })
                                                        }}
                                                    >
                                                        <SelectBasicItemIconWrap>
                                                            <Icon name="edit" />
                                                        </SelectBasicItemIconWrap>
                                                        Rename
                                                    </SelectBasicItem>
                                                    <SelectBasicItem
                                                        type="button"
                                                        disabled={
                                                            (documents !=
                                                                null &&
                                                                documents.length >
                                                                    0) ||
                                                            teamPermissionIsLoading ||
                                                            permission ===
                                                                'Viewer'
                                                        }
                                                        onClick={() => {
                                                            dismiss()
                                                            triggerDeletePecedentDatabase()
                                                        }}
                                                    >
                                                        <SelectBasicItemIconWrap>
                                                            <Icon name="trash" />
                                                        </SelectBasicItemIconWrap>
                                                        Delete database
                                                    </SelectBasicItem>
                                                </Tooltip>
                                            </DropdownContent>
                                        )}
                                    </Dropdown>
                                </>
                            }
                            rhsAux={
                                <SidebarToggle
                                    value={!!focusedDocument}
                                    onClick={() => {
                                        if (focusedDocument) {
                                            setFocusedDocument(null)
                                            return
                                        }
                                        if (documents[0]) {
                                            setFocusedDocument(documents[0])
                                        }
                                    }}
                                />
                            }
                        />
                        <ContentWithSidebarContainer>
                            <Pad>
                                {dataRoom && (
                                    <>
                                        <h1 className="m-b-50">
                                            {dataRoom.name}
                                        </h1>
                                        <DataRoomDocumentUploader
                                            dataRoomId={dataRoom.id}
                                            onDocumentAdded={() => {
                                                invalidateDataRoomQuery(id)
                                                invalidateDataRoomDocumentsQuery(
                                                    id
                                                )
                                            }}
                                            onDocumentDeleted={() => {
                                                invalidateDataRoomQuery(id)
                                                invalidateDataRoomDocumentsQuery(
                                                    id
                                                )
                                            }}
                                        />
                                        {documents != null &&
                                            documents.length > 0 && (
                                                <>
                                                    <Separator
                                                        className={cx(
                                                            'm-t-50',
                                                            'm-b-50'
                                                        )}
                                                    />
                                                    <h3>All documents</h3>
                                                    <p>
                                                        This database has{' '}
                                                        {
                                                            dataRoom._count
                                                                .documents
                                                        }{' '}
                                                        documents
                                                    </p>

                                                    <Table
                                                        columns={[
                                                            {
                                                                key: 'originalFileName',
                                                                title: 'Name',
                                                                width: 'minmax(min-content, 90%)',
                                                                render: ({
                                                                    originalFileName,
                                                                }) => (
                                                                    <div
                                                                        className={cx(
                                                                            'd-flex',
                                                                            'g-10'
                                                                        )}
                                                                    >
                                                                        <FileTypeIcon
                                                                            fileName={
                                                                                originalFileName
                                                                            }
                                                                        />
                                                                        {
                                                                            originalFileName
                                                                        }
                                                                    </div>
                                                                ),
                                                            },
                                                            {
                                                                key: 'wordCount',
                                                                title: 'Words',
                                                            },
                                                            {
                                                                key: 'createdAt',
                                                                title: 'Added on',
                                                                render: ({
                                                                    createdAt,
                                                                }) => (
                                                                    <DateRenderer
                                                                        value={
                                                                            createdAt
                                                                        }
                                                                    />
                                                                ),
                                                            },
                                                            {
                                                                key: 'meatballMenu',
                                                                title: '',
                                                                render: (
                                                                    doc
                                                                ) => (
                                                                    <>
                                                                        <Dropdown
                                                                            trigger={({
                                                                                onClick,
                                                                            }) => (
                                                                                <DotdotdotButton
                                                                                    onClick={(
                                                                                        event
                                                                                    ) => {
                                                                                        event.stopPropagation()
                                                                                        onClick()
                                                                                    }}
                                                                                    flip
                                                                                />
                                                                            )}
                                                                        >
                                                                            {(
                                                                                dismiss
                                                                            ) => (
                                                                                <DropdownContent>
                                                                                    <SelectBasicItem
                                                                                        type="button"
                                                                                        onClick={(
                                                                                            e
                                                                                        ) => {
                                                                                            e.stopPropagation()
                                                                                            dismiss()
                                                                                            downloadDocument(
                                                                                                doc
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        <SelectBasicItemIconWrap>
                                                                                            <DropdownIcon name="download" />
                                                                                        </SelectBasicItemIconWrap>
                                                                                        Download
                                                                                    </SelectBasicItem>
                                                                                    <SelectBasicItem
                                                                                        type="button"
                                                                                        onClick={(
                                                                                            e
                                                                                        ) => {
                                                                                            e.stopPropagation()
                                                                                            dismiss()
                                                                                            triggerDeleteDocument(
                                                                                                doc
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        <SelectBasicItemIconWrap>
                                                                                            <DropdownIcon name="trash" />
                                                                                        </SelectBasicItemIconWrap>
                                                                                        Delete
                                                                                    </SelectBasicItem>
                                                                                </DropdownContent>
                                                                            )}
                                                                        </Dropdown>
                                                                    </>
                                                                ),
                                                            },
                                                        ]}
                                                        data={documents}
                                                        onRowClick={(doc) => {
                                                            if (
                                                                focusedDocument?.id ===
                                                                doc.id
                                                            ) {
                                                                setFocusedDocument(
                                                                    null
                                                                )
                                                            } else {
                                                                setFocusedDocument(
                                                                    doc
                                                                )
                                                            }
                                                        }}
                                                        hoveredIndex={
                                                            hoveredRowIndex
                                                        }
                                                        noHorizontalPadding
                                                    />
                                                    {hasNextPage && (
                                                        <LoadMoreButton
                                                            onClick={
                                                                fetchNextPage
                                                            }
                                                            isLoading={
                                                                isFetchingNextPage
                                                            }
                                                        />
                                                    )}
                                                </>
                                            )}
                                    </>
                                )}
                            </Pad>
                            {!!focusedDocument && (
                                <Sidebar>
                                    <DocumentSidebarContent
                                        document={focusedDocument}
                                        triggerDeleteDocument={
                                            triggerDeleteDocument
                                        }
                                    />
                                </Sidebar>
                            )}
                        </ContentWithSidebarContainer>
                    </MainContainer>
                </Layout>
            </ShowSidebarProvider>
        </AccessDeniedHandler>
    )
}
